<template>
  <div>
    <div class="carousel">
      <div class="carousel-video">
        <div class="video-bg">
        <!-- <div class="video-bg" :style="'background-image:url('+homePoster[num]+')'"> -->
          <div class="video-shadow"></div>
        </div>
        <div class="video">
          <video-player
            class="video-player vjs-custom-skin"
            ref="videoPlayer"
            :playsinline="true"
            :options="playerOptions"
            style="width: 100%"
            @play="onPlayerPlay($event,index)"
            @pause="onPlayerPause($event)"
            @ended="onPlayerEnded($event)"
          ></video-player>
        </div>
      </div>
      <div class="car-bg">
        <div class="container row-between car-detail" style="">
          <div class="car-detail-t1 row-between">
            <div
              class="car-info"
              v-for="(item, index) in someList"
              :key="index"
              :style="[item.style]"
              @click="slideTo(index)"
              :class="num == index ? 'car-info-sp' : ''"
            >
              <img :src="item.imgBg" alt="" />
            </div>
            <img
              src="../../assets/img/arrow-left.png"
              class="arrow-left arrow-spe"
              @click="slidePre"
            />
            <img
              src="../../assets/img/arrow-right.png"
              class="arrow-right arrow-spe"
              @click="slideNext"
            />
          </div>
          <div class="car-detail-t2">智能社区分类，做社区大生意。</div>
          <!-- <div class="car-detail-t3" @click="joinUs">加盟我们</div> -->
        </div>
      </div>
    </div>
    <div class="" style="overflow: hidden">
      <div class="container row-between intro" style="align-items: flex-end">
        <div class="intro-cont">
          <div style="overflow: hidden">
            <div :class="[tab == 0 ? 'intro-margin-in' : 'intro-margin-out']">
              <div class="intro-cont-t1">
                精灵<span class="item-color">小松鼠。</span>
              </div>
              <div class="intro-cont-t2">SQUIRREL ELF</div>
            </div>
          </div>
          <div style="overflow: hidden">
            <div :class="[tab == 1 ? 'intro-margin-in' : 'intro-margin-out']">
              <div class="intro-cont-t1">
                品牌<span class="item-color">释义。</span>
              </div>
              <div class="intro-cont-t2">BRAND MEANING</div>
            </div>
          </div>

          <div :class="[tab == 0 ? 'intro-fade-in' : 'intro-fade-out']">
            <div class="intro-cont-t3">
              小松鼠（上海）环保科技有限公司（简称小松鼠公司）是一家致力于垃圾分类回收事业的互联网科技型企业，为上海市两网融合服务企业，成立于2018年10月，注册资本金1200万元人民币，是上海三环节能科技发展股份有限公司（股票代836115）关联公司。
            </div>
            <div class="intro-cont-t3">
              小松鼠通过在小区、楼宇、学校等公共场所设立智能圾分类回收站，通过“互联网”+“物联网”采取有偿回收的方式，引导人们主动进行垃圾分类，助力环保事业。
            </div>
          </div>
          <div :class="[tab == 1 ? 'intro-fade-in' : 'intro-fade-out']">
            <div class="intro-cont-t3">
              松鼠会在秋天将采集来的大部分果实埋藏起来，直至生根发芽。这种取之于自然，反哺自然的行为与小松鼠公司特点高度契合。
            </div>
            <div class="intro-cont-t3">
              此外，松鼠外形可爱，性格活泼，行动敏捷，凡是有松鼠出没的地方必然是环境优良、生态宜居之地。
            </div>
            <div class="intro-cont-t3">
              因此，小松鼠公司将“让垃圾分类成为一种生活方式”作为企业愿景，意在通过精灵小松鼠项目的实施，让垃圾分类观念深入人心，润物无声。
            </div>
          </div>
          <div class="intro-cont-t4 row-start">
            <div
              class="intro-cont-line"
              :class="[tab == 0 ? 'intro-cont-line-sp' : '']"
              @click="introTab(tab)"
            ></div>
            <div
              class="intro-cont-line"
              :class="[tab == 1 ? 'intro-cont-line-sp' : '']"
              @click="introTab(tab)"
            ></div>
          </div>
        </div>
        <div class="intro-img" v-if="tab == 0">
          <img src="../../assets/img/homepage/homepage-1.png" />
        </div>
        <div class="intro-img" v-else>
          <img src="../../assets/img/homepage/homepage-2.png" />
        </div>
        <img
          src="../../assets/img/arrow-left.png"
          class="arrow-left arrow-left-sp arrow-spe"
          @click="introTab(tab)"
        />
        <img
          src="../../assets/img/arrow-right.png"
          class="arrow-right arrow-right-sp arrow-spe"
          @click="introTab(tab)"
        />
      </div>
    </div>
    <div class="news">
      <div class="container">
        <div class="news-tab row-between">
          <div class="row-start">
            <div
              class="news-tab-li index-active"
              :class="newsTab == 0 ? 'index-active-sp' : ''"
              @click="newsTabchange(0)"
            >
              领导关怀
            </div>
            <div
              class="news-tab-li index-active"
              :class="newsTab == 1 ? 'index-active-sp' : ''"
              @click="newsTabchange(1)"
            >
              行业动态
            </div>
            <div
              class="news-tab-li index-active"
              :class="newsTab == 2 ? 'index-active-sp' : ''"
              @click="newsTabchange(2)"
            >
              环保百科
            </div>
          </div>
          <div class="">
            <div class="bg-shadow news-more news-more1">
              <div class="bg-active"></div>
              <div class="news-img-t1-sp news-img-t1-center" @click="seeMore">
                查看更多<img
                  src="../../assets/img/news-more1.png"
                  alt=""
                  class="arrow-white arrow-white-t1"
                /><img
                  src="../../assets/img/news-more3.png"
                  alt=""
                  class="arrow-white arrow-white-t2"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="news-content row-between" v-if="newsTab == 0">
          <div
            v-for="(item, index) in companyData"
            :key="index"
            @click="goCompanyNews(item.companyNewsId)"
          >
            <div class="news-img bg-shadow" v-if="index == 0">
              <div class="news-img-de"><img :src="item.imageUrl" /></div>
              <div class="news-img-t1 ellipsis bg-shadow">
                <div class="bg-active"></div>
                <div
                  class="news-img-t1-sp"
                  style="text-indent: 20px"
                  v-html="item.companyNewsTitle"
                ></div>
              </div>
            </div>
          </div>
          <div class="news-info">
            <div style="height: 382px; padding-top: 7px">
              <div
                v-for="(item, index) in companyData"
                :key="index"
                @click="goCompanyNews(item.companyNewsId)"
              >
                <div class="news-info-de" v-if="index == 0">
                  <div class="news-info-li-sp">
                    <div class="news-info-title ellipsis">
                      {{ item.companyNewsTitle }}
                    </div>
                    <div class="news-info-cont">
                      {{ item.companyNewsSubTitle }}
                    </div>
                  </div>
                </div>
                <div class="news-info-de" v-if="index > 0">
                  <div class="news-info-li row-between">
                    <div class="row-start">
                      <div class="news-info-t1">新闻</div>
                      <div class="news-info-t2 ellipsis">
                        {{ item.companyNewsTitle }}
                      </div>
                    </div>
                    <div class="news-info-t3">
                      {{ item.createDtme | formatDate(item.createDtme) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="news-img-t1 bg-shadow">
              <div class="bg-active"></div>
              <div
                class="news-img-t1-sp news-img-t1-center news-more1"
                style="text-align: center"
                @click="seeMore"
              >
                查看更多<img
                  src="../../assets/img/news-more2.png"
                  alt=""
                  class="arrow-white arrow-white-t1"
                  style="right: 40%"
                /><img
                  src="../../assets/img/news-more3.png"
                  alt=""
                  class="arrow-white arrow-white-t2"
                  style="right: 40%"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="news-content row-between" v-if="newsTab == 1">
          <div
            v-for="(item, index) in industryData"
            :key="index"
            @click="goIndustryNews(item.industryNewsId)"
          >
            <div class="news-img bg-shadow" v-if="index == 0">
              <div class="news-img-de"><img :src="item.imageUrl" /></div>
              <div class="news-img-t1 ellipsis bg-shadow">
                <div class="bg-active"></div>
                <div
                  class="news-img-t1-sp"
                  style="text-indent: 20px"
                  v-html="item.industryNewTitle"
                ></div>
              </div>
            </div>
          </div>
          <div class="news-info">
            <div style="height: 382px; padding-top: 7px">
              <div
                v-for="(item, index) in industryData"
                :key="index"
                @click="goIndustryNews(item.industryNewsId)"
              >
                <div class="news-info-de" v-if="index == 0">
                  <div class="news-info-li-sp">
                    <div class="news-info-title ellipsis">
                      {{ item.industryNewTitle }}
                    </div>
                    <div class="news-info-cont">
                      {{ item.industryNewSubTitle }}
                    </div>
                  </div>
                </div>
                <div class="news-info-de" v-if="index > 0">
                  <div class="news-info-li row-between">
                    <div class="row-start">
                      <div class="news-info-t1">新闻</div>
                      <div class="news-info-t2 ellipsis">
                        {{ item.industryNewTitle }}
                      </div>
                    </div>
                    <div class="news-info-t3">
                      {{ item.createDtme | formatDate(item.createDtme) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="news-img-t1 bg-shadow">
              <div class="bg-active"></div>
              <div
                class="news-img-t1-sp news-img-t1-center news-more1"
                style="text-align: center"
                @click="seeMore"
              >
                查看更多<img
                  src="../../assets/img/news-more2.png"
                  alt=""
                  class="arrow-white arrow-white-t1"
                  style="right: 40%"
                /><img
                  src="../../assets/img/news-more3.png"
                  alt=""
                  class="arrow-white arrow-white-t2"
                  style="right: 40%"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="news-content row-between" v-if="newsTab == 2">
          <div
            v-for="(item, index) in epData"
            :key="index"
            @click="goEp(item.epId)"
          >
            <div class="news-img bg-shadow" v-if="index == 0">
              <div class="news-img-de"><img :src="item.imageUrl" /></div>
              <div class="news-img-t1 ellipsis bg-shadow">
                <div class="bg-active"></div>
                <div
                  class="news-img-t1-sp"
                  style="text-indent: 20px"
                  v-html="item.epTitle"
                ></div>
              </div>
            </div>
          </div>
          <div class="news-info">
            <div style="height: 382px; padding-top: 7px">
              <div
                v-for="(item, index) in epData"
                :key="index"
                @click="goEp(item.epId)"
              >
                <div class="news-info-de" v-if="index == 0">
                  <div class="news-info-li-sp">
                    <div class="news-info-title ellipsis">
                      {{ item.epTitle }}
                    </div>
                    <div class="news-info-cont">
                      {{ item.epSubTitle }}
                    </div>
                  </div>
                </div>
                <div class="news-info-de" v-if="index > 0">
                  <div class="news-info-li row-between">
                    <div class="row-start">
                      <div class="news-info-t1">新闻</div>
                      <div class="news-info-t2 ellipsis">
                        {{ item.epTitle }}
                      </div>
                    </div>
                    <div class="news-info-t3">
                      {{ item.createDtme | formatDate(item.createDtme) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="news-img-t1 bg-shadow">
              <div class="bg-active"></div>
              <div
                class="news-img-t1-sp news-img-t1-center news-more1"
                style="text-align: center"
                @click="seeMore"
              >
                查看更多<img
                  src="../../assets/img/news-more2.png"
                  alt=""
                  class="arrow-white arrow-white-t1"
                  style="right: 40%"
                /><img
                  src="../../assets/img/news-more3.png"
                  alt=""
                  class="arrow-white arrow-white-t2"
                  style="right: 40%"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      someList: [
        {
          imgBg: require("../../assets/img/homepage/homepage-a1.png"),
          style: {
            width: "1200px",
            background: "rgba(0,0,0,.1)",
          },
        },
        {
          imgBg: require("../../assets/img/homepage/homepage-a2.png"),
          style: {
            width: "1200px",
            background: "rgba(0,0,0,.1)",
          },
        },
        {
          imgBg: require("../../assets/img/homepage/homepage-a3.png"),
          style: {
            width: "1200px",
            background: "rgba(0,0,0,.1)",
          },
        },
      ],
      videoUrl: "https://image.squirrelf.com/poster/xuanchuan1.mp4",

      num: 0, //当前轮播下标
      tab: 0,
      newsTab: 0,
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "",
            src: "https://image.squirrelf.com/poster/xuanchuan1.mp4", //url地址
          },
        ],
        poster: "https://image.squirrelf.com/poster/homepage-a1.png", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
      videoArray: [
        "https://image.squirrelf.com/poster/xuanchuan1.mp4",
        "https://image.squirrelf.com/poster/xuanchuan2.mp4",
        "https://image.squirrelf.com/poster/xuanchuan3.mp4",
      ],
      homePoster: [
        "https://image.squirrelf.com/poster/homepage-a1.png",
        "https://image.squirrelf.com/poster/homepage-a2.png",
        "https://image.squirrelf.com/poster/homepage-a3.png",
      ],
      companyData: [],
      industryData: [],
      epData: [],
    };
  },

  mounted: function () {
    //调取本地的get(就在下面)
    var that=this
    that.getCompanyNews();
    setTimeout(function(){
      that.getIndustryNews()
      that.getEp()
    },3000)
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    slideNext() {
      this.num = this.num + 1 == 3 ? 0 : this.num + 1;
      this.playerOptions.sources[0].src = this.videoArray[this.num];
      this.playerOptions.poster = this.homePoster[this.num];
    },
    slideTo(num) {
      this.num = num;
      this.playerOptions.sources[0].src = this.videoArray[this.num];
      this.playerOptions.poster = this.homePoster[this.num];
    },
    slidePre() {
      this.num = this.num - 1 == -1 ? 2 : this.num - 1;
      this.playerOptions.sources[0].src = this.videoArray[this.num];
      this.playerOptions.poster = this.homePoster[this.num];
    },
    introTab(e) {
      e -= 1;
      console.log(e);
      if (e % 2 == 0) {
        this.tab = 0;
      } else {
        this.tab = 1;
      }
    },
    newsTabchange(e) {
      this.newsTab = e;

    },
    onPlayerPlay(player,index) {
      console.log("player play!", player, this.$refs.videoPlayer.player.play());
    },
    onPlayerPause(player) {
      console.log("player pause!", player);
    },
    onPlayerEnded(player) {
      console.log("player ended!", player);
    },
    //查看更多
    seeMore() {
      this.$router.push({ path: "/dynamics" });
    },
    //领导关怀
    goCompanyNews(i) {
      console.log(i);
      let routeData = this.$router.resolve({
        path: "companyNews",
        query: { id: i },
      });
      window.open(routeData.href, "_blank");
    },
    goIndustryNews(i) {
      console.log(i);
      let routeData = this.$router.resolve({
        path: "industryNews",
        query: { id: i },
      });
      window.open(routeData.href, "_blank");
    },
    goEp(i) {
      //console.log(i)
      let routeData = this.$router.resolve({
        path: "ep",
        query: { id: i },
      });
      window.open(routeData.href, "_blank");
    },
    getCompanyNews: function () {
      var that = this;
      //console.log(that.$api.showCompanyNews);
      that
        .$http(that.$api.showCompanyNews + "?limit=5")
        .then((res) => {
          let conts = res.data.result;
          console.log(conts);
          for (let i = 0; i < conts.length; i++) {
            if (conts[i].status == 0) {
              that.companyData.push(conts[i]);
            }
          }
          //console.log(that.companyData);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getIndustryNews: function () {
      var that = this;
      //console.log(that.currentPage)
      that
        .$http(that.$api.showIndustryNews + "?limit=5")
        .then((res) => {
          let conts = res.data.result;
          console.log(conts);
          for (let i = 0; i < conts.length; i++) {
            if (conts[i].status == 0) {
              that.industryData.push(conts[i]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getEp: function () {
      var that = this;
      that
        .$http(that.$api.showEp + "?limit=5")
        .then((res) => {
          let conts = res.data.result;
          console.log(conts);
          for (let i = 0; i < conts.length; i++) {
            if (conts[i].status == 0) {
              that.epData.push(conts[i]);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    joinUs(){
      this.$router.push({ path: "/poolplan" });
    },
  },
};
</script>

<style>
.carousel-video {
  width: 100%;
  height: 675px;
  overflow: hidden;
  position: relative;
}
.video-bg {
  width: 100%;
  height: 675px;
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  background: #000032;
  /* background-image: url('https://image.squirrelf.com/poster/homepage-a1.png'); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* -webkit-filter: blur(3px); 
  -moz-filter: blur(3px);
  -ms-filter: blur(3px);    
  filter: blur(3px);
  filter:progid:DXImageTransform.Microsoft.Blur(PixelRadius='3');  */
}
.video-shadow {
  width: 100%;
  height: 675px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 250;
  /* background: rgba(0, 0, 0, 0.5); */
}
.video {
  width: 1200px;
  margin: 0 auto;
  height: 675px;
  position: relative;
  z-index: 500;
}

.arrow-left,
.arrow-right {
  width: 32px;
  height: 32px;
  position: absolute;
  cursor: pointer;
}
.arrow-left {
  top: 50%;
  margin-top: -15px;
  left: -15px;
}
.arrow-spe:hover{
  opacity: .7;
}
.arrow-right {
  top: 50%;
  margin-top: -15px;
  right: -15px;
}
.arrow-left-sp {
  left: -100px;
}
.arrow-right-sp,
.arrow-left-sp {
  width: 48px;
  height: 48px;
}
.arrow-right-sp {
  right: -100px;
}
.intro-fade-out {
  opacity: 0;
  height: 0;
}
.intro-fade-in {
  opacity: 1;
  transition: all 0.2s linear;
}
.intro-margin-out {
  opacity: 0;
  margin-left: -300px;
  /* transition: all 0.2s linear; */
  height: 0;
}
.intro-margin-in {
  margin: 0;
  transition: all 0.3s linear;
  opacity: 1;
}
</style>